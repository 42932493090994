import React from 'react'

import './MobileBanner.css'

export default function MobileBanner() {
  
  return (
    <div className="mobile-banner-container">

    </div>
  )
}